import { FC, memo } from 'react'
import { BTN_SEARCH } from '@components/utils/textVariables'
interface Props {
  id?: string
  onClick: any
}

const Searchbar: FC<Props> = ({ id = 'search', onClick }) => {
  return (
    <div className="flow-root search-wrapper w-10 px-1 sm:w-12 min-w-50">
      <button
        onClick={onClick}
        className="flex flex-col items-center justify-center grid-cols-1 mx-auto text-center group align-center"
        aria-label="Search"
      >
        <span className="sr-only" aria-label="Search">
          {BTN_SEARCH}
        </span>
        <i className="sprite-icon sprite-search"></i>
      </button>
    </div>
  )
}

export default memo(Searchbar)