import { useState, useEffect, useRef, useCallback } from 'react'
import axios from '@services/axiosInterceptorInstance'
import {
  APP_VERSION,
  EmptyGuid,
  NEXT_ELASTIC_SEARCH_API,
  NEXT_GET_PAGE_PREVIEW_CONTENT,
} from '@components/utils/constants'
import { useRouter } from 'next/router'
import { Searchbar } from '@components/common'
import { useUI } from '@components/ui/context'
import { BTN_SEARCH, SEARCH_PLACEHOLDER } from '@components/utils/textVariables'
import {
  ArrowLeft,
  ClockIcon,
  Cross,
  SearchIcon,
  SearchSuggestionIcon,
} from '@components/icons'
import { useClickOutside } from '@commerce/utils/use-hook'
import { isMobileOnly } from 'react-device-detect'
import { getCurrentPage } from '@framework/utils/app-util'
import { GA_EVENT } from 'hooks/ga_event.modal'
import { recordGA4Event } from '@components/services/analytics/ga4'
import { useMoEngageEvent } from 'hooks/useMoengageEvent'
import { useDebounce } from 'hooks/useDebounce'
import Cookies from 'js-cookie'
import SearchRoundWidget from './SearchRoundWidget'

declare const window: any
declare const AF: any
const DEBOUNCE_TIMER = 200

interface CountOfAPI {
  [key: string]: number
}

export default function Search({
  keywords,
  deviceInfo = {},
  previousPath,
}: any) {
  const Router = useRouter()
  const trackMoEngageEvent = useMoEngageEvent()
  const currentUrl = Router.asPath
  const hasSearchParam = currentUrl.includes('#search')
  const searchRef = useRef<HTMLDivElement>(null)
  const searchInputRef = useRef<HTMLInputElement | null>(null)
  const childRef = useRef<HTMLDivElement>(null)
  const ele = document.getElementById('search-bar')
  const [inputValue, setInputValue] = useState('')
  const [keywordList, setKeywordList] = useState<Record<string, string>[]>([])
  const [recentSearchList, setRecentSearchList] = useState<
    Record<string, string>[]
  >([])
  const [searchBannerList, setSearchBannerList]: any = useState({})
  const { isMobile, isIPadorTablet } = deviceInfo
  let deviceCheck = ''
  if (isMobile || isIPadorTablet) {
    deviceCheck = 'Mobile'
  } else {
    deviceCheck = 'Desktop'
  }
  const router = useRouter()

  const { setShowSearchBar, showSearchBar, user } = useUI()

  useClickOutside(searchRef, () => {
    closeWrapper()
  })

  useEffect(() => {
    const userRecentSearchList: Record<string, string>[] = JSON?.parse(
      Cookies?.get('userSearchData') || '[]'
    )
    if (userRecentSearchList.length > 0) {
      setRecentSearchList(userRecentSearchList)
    }
  }, [router.asPath])

  useEffect(() => {
    if (searchInputRef?.current) {
      searchInputRef?.current?.focus()
    }
    getAppVersion()
  }, [])

  const getAppVersion = async () => {
    try {
      const {
        data: { topcategory2search, topcategorysearch },
      }: any = await axios.get(NEXT_GET_PAGE_PREVIEW_CONTENT, {
        params: {
          slug: APP_VERSION,
          workingVersion: process.env.NODE_ENV === 'production' ? true : true, // TRUE for preview, FALSE for prod.
          channel: 'Web',
        },
      })

      setSearchBannerList({ topcategorysearch, topcategory2search })
    } catch (error) {
      console.error(':: error ', error)
    }
  }

  const searchKeywordGAEvent = (clickString: string) => {
    let url = currentUrl.replace('#search', '')
    recordGA4Event(window, GA_EVENT.SEARCH, {
      device: deviceCheck,
      current_page: getCurrentPage(url),
      searched_string: inputValue,
      clicked_string: clickString,
      loggedin_status: user?.userId && user?.userId !== EmptyGuid,
    })
  }

  const onSearchClick = () => {
    if (typeof window !== 'undefined') {
      recordGA4Event(window, GA_EVENT.SEARCH_INIT, {
        device: deviceCheck,
        current_page: getCurrentPage(),
        button: 'search',
        loggedin_status: user?.userId && user?.userId !== EmptyGuid,
      })
    }

    if (hasSearchParam) {
      const updatedUrl = currentUrl.replace('#search', '')
      Router.push(updatedUrl)
    } else {
      const updatedUrl = `${currentUrl}#search`
      Router.push(updatedUrl)
    }
    isMobileOnly && document.body.classList.add('hide-body-scroll')
  }

  const getKeywordFromElastic = async (value: string) => {
    try {
      const response: any = await axios.post(NEXT_ELASTIC_SEARCH_API, {
        query: value,
      })
      const {
        status,
        data: {
          results: { documents },
        },
      } = response
      if (status == 200 && documents?.length > 0) {
        setKeywordList(documents)
      } else {
        setKeywordList([{ suggestion: value }])
      }
    } catch (err) {
      console.error('err', err)
    }
  }

  const closeWrapper = () => {
    setShowSearchBar(false)
    const updatedUrl = currentUrl.split('#search')[0]
    setInputValue('')
    setKeywordList([])
    Router.push(updatedUrl)
  }

  const removeCssScrollHide = () => {
    isMobileOnly && document.body.classList.remove('hide-body-scroll')
  }

  const clearInput = () => {
    setInputValue('')
    setKeywordList([])
    if (searchInputRef.current && searchInputRef.current.value !== undefined) {
      searchInputRef.current.value = ''
    }
    window?.location.replace('#search;' + '')
  }

  useEffect(() => {
    if (hasSearchParam) {
      setShowSearchBar(true)
    } else {
      setShowSearchBar(false)
    }
  }, [currentUrl])

  const onSearchChange = (e: any) => {
    setInputValue(e.target.value)
    if (e.target.value.length > 2) {
      getKeywordFromElastic(e.target.value)
    } else {
      setKeywordList([])
    }
    const input = e.target.value.replace(/\s+/g, '-')
    window?.location.replace('#search;' + input)
  }
  const onSearchChangeDebounce = useCallback(
    useDebounce(onSearchChange, DEBOUNCE_TIMER),
    []
  )

  useEffect(() => {
    const value = Router.asPath.split('#search;')[1]
    if (value) {
      const searchValue = value.replace('-', ' ')
      setInputValue(searchValue)
      getKeywordFromElastic(searchValue)
    }
    return () => {
      setInputValue('')
    }
  }, [Router.asPath])

  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement
    if (target.scrollTop > 1100 && target.scrollTop < 1150) {
      ele?.blur()
    }
  }

  const navigateToSearch = (name: string) => {
    saveDataToCookie(name)
    isMobileOnly && document.body.classList.remove('hide-body-scroll')
    searchKeywordGAEvent(name)
    router.push(`/search/${name}`)
    setKeywordList([])
  }

  const saveDataToCookie = (name: string) => {
    const userSearchData: Record<string, string>[] =
      JSON?.parse(Cookies?.get('userSearchData') || '[]') || []
    if (isValueSaveInCookie(userSearchData, name)) {
      return true
    }
    if (userSearchData.length == 5) {
      userSearchData.pop()
      userSearchData.unshift({ suggestion: name })
    } else {
      userSearchData.unshift({ suggestion: name })
    }
    Cookies.set('userSearchData', JSON.stringify(userSearchData))
  }

  const isValueSaveInCookie = (
    userSaveCookieData: Record<string, string>[],
    newSearchKey: string
  ) => {
    for (let data of userSaveCookieData) {
      if (data.suggestion == newSearchKey) {
        return true
      }
    }
    return false
  }

  const getOptionList = (
    list: Record<string, string>[],
    isRecentSearch = true
  ) => {
    // if (list.length == 0) {
    //   return <></>
    // }
    const optionList = list?.map((keyword: any, index: number) => {
      return (
        <div
          key={index}
          className={`flex justify-between items-center py-3 mx-4 text-sm search-result cursor-pointer border-b-[1px] border-[#F1ECE0] text-primary`}
          onClick={() => navigateToSearch(keyword?.suggestion)}
        >
          {keyword?.suggestion}
          {isRecentSearch ? (
            <ClockIcon width="20" height="20" />
          ) : (
            <SearchSuggestionIcon />
          )}
        </div>
      )
    })

    if (isRecentSearch) {
      return (
        <>
          {list?.length > 0 ? (
            <div className={`${isMobileOnly ? '' : 'pt-2.5'}`}>
              <div className="text-xs mx-4 mb-1 font-normal text-[#251000A3]">
                Recent Searches
              </div>
              {optionList}
            </div>
          ) : (
            <></>
          )}
          {getQuickAccess()}
        </>
      )
    } else {
      return (
        <>
          {list?.length > 0 ? (
            <div className={`${isMobileOnly ? '' : 'pt-2.5'}`}>
              <div className="text-xs mx-4 mb-1 font-normal text-[#251000A3]">
                Suggestions
              </div>
              {optionList}
            </div>
          ) : (
            ''
          )}
        </>
      )
    }
  }

  const getQuickAccess = () => {
    return (
      <>
        {Object?.keys(searchBannerList)?.length > 0 &&
        (searchBannerList?.topcategorysearch ||
          searchBannerList?.topcategory2search) &&
        isMobileOnly ? (
          <div className="pt-3.5">
            <div className="text-xs mx-4 mb-3 font-normal text-[#251000A3]">
              Quick Access
            </div>
            {
              <SearchRoundWidget
                data={searchBannerList}
                removeCssScrollHide={removeCssScrollHide}
              />
            }
          </div>
        ) : (
          ''
        )}
      </>
    )
  }

  const handleEnterPress = (event: any) => {
    if (event?.key == 'Enter' && event?.target?.value?.length > 0) {
      isMobileOnly && document.body.classList.remove('hide-body-scroll')
      saveDataToCookie(event?.target?.value)
      searchKeywordGAEvent(event?.target?.value)
      router.push(`/search/${event?.target?.value}`)
      setKeywordList([])
    }
  }

  const searchButtonClick = () => {
    if (inputValue.length > 0) {
      isMobileOnly && document.body.classList.remove('hide-body-scroll')
      saveDataToCookie(inputValue)
      searchKeywordGAEvent(inputValue)
      router.push(`/search/${inputValue}`)
      setKeywordList([])
    }
  }

  if (!showSearchBar) return <Searchbar onClick={onSearchClick} />

  if (isMobileOnly)
    return (
      <div
        onScroll={onScroll}
        className="fixed overflow-y-auto overflow-x-hidden hide-scrollbar overscroll-y-contain h-full w-full z-99 bg-white top-0 left-0"
      >
        <div className=" search-contain overscroll-y-contain  w-full px-0 py-0 mt-0 search-min-width-410">
          <div className="flex items-center z-999 justify-center bg-white sticky top-0 py-4">
            <div className="w-2/12 pl-2 ">
              <div className=" left-0 py-0 text-gray-400">
                <ArrowLeft
                  className="w-8 h-5 text-black"
                  aria-hidden="true"
                  onClick={closeWrapper}
                />
              </div>
            </div>
            <div className="w-10/12 pr-6 mx-auto mb-0 sm:w-full sm:pr-0">
              <div className="flex items-center justify-center gap-1.5 flex-row px-2 py-3 border border-orange-500 rounded-sm bg-gray-50">
                <label className="hidden" htmlFor={'search-bar'}>
                  {BTN_SEARCH}
                </label>
                <div
                  className="relative left-0 py-0 text-gray-400 cursor-pointer"
                  onClick={searchButtonClick}
                >
                  <SearchIcon />
                </div>
                <input
                  id="search-bar"
                  autoFocus
                  ref={searchInputRef}
                  defaultValue={inputValue}
                  className="w-full caret-orange-500 min-w-0 px-0 py-0 text-sm text-gray-700 placeholder-gray-500 bg-transparent appearance-none focus:outline-none focus:ring-0"
                  placeholder={SEARCH_PLACEHOLDER}
                  onChange={onSearchChangeDebounce}
                  onKeyDown={handleEnterPress}
                />
                <div className="relative right-0 py-0 text-gray-400">
                  <div className="relative right-0 py-0 text-gray-400">
                    <Cross
                      className="w-5 h-5 text-gray-500"
                      aria-hidden="true"
                      onClick={clearInput}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div ref={childRef} className={` w-full min-w-410 `}>
            {keywordList?.length > 0 || inputValue.length > 0
              ? getOptionList(keywordList, false)
              : getOptionList(recentSearchList, true)}
          </div>
        </div>
      </div>
    )

  return (
    <div className="searchbar-wrapper">
      <div
        className={`absolute w-full px-0 py-4 bg-white sm:border-b lg:border-none z-999 ${
          isMobileOnly && 'py-5'
        }`}
      >
        <div className="relative w-full px-0 pr-0 bg-white sm:px-0 sm:width-50  z-999">
          <div
            ref={searchRef}
            className="relative w-full  search-min-width-450 sm:absolute search-wrapper sm:top-2/4 sm:-translate-y-2/4 "
          >
            <div className="relative flex items-center justify-center w-full px-0 py-0 mt-0 search-min-width-410 sm:float-right">
              <div className="w-2/12 pl-2 sm:hidden">
                <div className="relative left-0 py-0 text-gray-400">
                  <ArrowLeft
                    className="w-8 h-5 text-black"
                    aria-hidden="true"
                    onClick={closeWrapper}
                  />
                </div>
              </div>
              <div className="w-10/12 pr-6 mx-auto mb-0 sm:w-full sm:pr-0">
                <div className="flex items-center justify-center gap-1.5 flex-row px-2 py-3 border border-orange-500 rounded-sm bg-gray-50">
                  <label className="hidden" htmlFor={'search-bar'}>
                    {BTN_SEARCH}
                  </label>
                  <div
                    className="relative left-0 py-0 text-gray-400 cursor-pointer"
                    onClick={searchButtonClick}
                  >
                    <SearchIcon />
                  </div>
                  <input
                    id={'search-bar'}
                    defaultValue={inputValue}
                    autoFocus
                    className="w-full caret-orange-500 min-w-0 px-0 py-0 text-sm text-gray-700 placeholder-gray-500 bg-transparent appearance-none focus:outline-none focus:ring-0"
                    placeholder={SEARCH_PLACEHOLDER}
                    onChange={onSearchChangeDebounce}
                    onKeyDown={handleEnterPress}
                  />
                  <div className="relative right-0 py-0 text-gray-400">
                    <div className="relative right-0 py-0 text-gray-400">
                      <Cross
                        className="w-5 h-5 text-gray-500"
                        aria-hidden="true"
                        onClick={closeWrapper}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="absolute overscroll-y-contain grid w-full grid-cols-1 search-top bg-white top-16 sm:mx-0 md:grid-cols-1 lg:grid-cols-1 min-w-410 ${
                    hide-scrollbar max-h-[50vh] border-gray-200 shadow sm:border overflow-auto"
              >
                {keywordList?.length > 0 || inputValue.length > 0
                  ? getOptionList(keywordList, false)
                  : getOptionList(recentSearchList, true)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
