import React from 'react'
import Link from 'next/link'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import { generateUri } from '@commerce/utils/uri-util'
import { IMG_PLACEHOLDER } from '@components/utils/textVariables'
import { removePrecedingSlash } from '@framework/utils/app-util'
import { Navigation, Grid } from 'swiper'
import 'swiper/css/grid'

const SearchRoundWidget = ({ data, removeCssScrollHide }: any) => {
  const css = { maxWidth: '100%', height: 'auto' }
  return (
    <>
      <div className="flex flex-col gap-2 px-0 home-banner-swiper swiper-container m-hide-navigation">
        <Swiper
          slidesPerView={4.5}
          spaceBetween={10}
          navigation={true}
          loop={false}
          freeMode={true}
          grid={{
            rows: 2,
          }}
          modules={[Navigation, Grid]}
          slidesOffsetBefore={16}
          slidesOffsetAfter={16}
          breakpoints={{
            640: { slidesPerView: 4.5 },
            768: { slidesPerView: 5 },
            1024: { slidesPerView: 7.5 },
            1520: { slidesPerView: 9.5 },
          }}
        >
          {data &&
            data?.topcategorysearch?.map((product?: any, ipx?: number) => {
              const link = product?.topcategorysearch_link
              const imageUrl = product?.topcategorysearch_categoryimage
              const title = product.topcategorysearch_title

              return (
                <SwiperSlide className="flex flex-col px-0 group" key={ipx}>
                  <Link
                    href={`/${removePrecedingSlash(link)}`}
                    legacyBehavior
                    passHref
                  >
                    <a
                      className=""
                      onClick={() => {
                        removeCssScrollHide()
                      }}
                    >
                      <div className="flex flex-col justify-center image-container home-category">
                        <img
                          src={
                            generateUri(imageUrl, 'fm=webp&h=100&q=50') ||
                            IMG_PLACEHOLDER
                          }
                          width={120}
                          height={120}
                          style={css}
                          alt={title}
                          className="image sm:group-hover:card-hover-shadow"
                        />
                      </div>
                      <div className="flex flex-col justify-center my-2">
                        <h3 className="text-xs font-semibold text-black sm:text-sm">
                          {title}
                        </h3>
                      </div>
                    </a>
                  </Link>
                </SwiperSlide>
              )
            })}

          {data &&
            data?.topcategory2search?.map((product?: any, ipx?: number) => {
              const link = product?.topcategory2search_link
              const imageUrl = product?.topcategory2search_categoryimage
              const title = product.topcategory2search_title

              return (
                <SwiperSlide className="flex flex-col px-0 group" key={ipx}>
                  <Link
                    href={`/${removePrecedingSlash(link)}`}
                    legacyBehavior
                    passHref
                  >
                    <a
                      className=""
                      onClick={() => {
                        removeCssScrollHide()
                      }}
                    >
                      <div className="flex flex-col justify-center image-container home-category">
                        <img
                          src={
                            generateUri(imageUrl, 'fm=webp&h=100&q=50') ||
                            IMG_PLACEHOLDER
                          }
                          width={120}
                          height={120}
                          style={css}
                          alt={title}
                          className="image sm:group-hover:card-hover-shadow"
                        />
                      </div>
                      <div className="flex flex-col justify-center my-2">
                        <h3 className="text-xs font-semibold text-black sm:text-sm">
                          {title}
                        </h3>
                      </div>
                    </a>
                  </Link>
                </SwiperSlide>
              )
            })}
        </Swiper>
      </div>
    </>
  )
}

export default SearchRoundWidget
